<nav class="navbar" role="navigation" aria-label="main navigation">
  <div class="navbar-brand">
    <a class="navbar-item" [routerLink]="['/areas']">
      <img rel="preload" as="image" src="assets/logo3.svg" width="80" height="80" alt="speedyjedzonko - logo">
    </a>

    <a role="button" #navBurger (click)="toggleNavbar()" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div id="navbarBasicExample" #navMenu class="navbar-menu">
<!--    <div class="navbar-start is-size-6 has-text-weight-medium ml-6">-->
<!--      <a class="navbar-item" href="/areas">-->
<!--        Lokalizacje-->
<!--      </a>-->
<!--    </div>-->

    <div class="navbar-end">
      <div class="navbar-item">
        <div class="buttons">
          <a *ngIf="loggedin" class="button is-info" [routerLink]="['/account']">
            <mat-icon class="mr-1">person</mat-icon><strong>Moje konto</strong>
          </a>
          <a class="button is-primary is-size-6" [routerLink]="['/cart']">
            <mat-icon [matBadge]="($cartItems | async)?.length" matBadgeOverlap="true" [matBadgeHidden]="($cartItems | async)?.length === 0" class="is-size-5 mr-1">shopping_cart</mat-icon> <strong class="is-size-6">Koszyk {{ ($cart | async)?.TotalPrice| number: '1.2-2' }} zł</strong>
          </a>
          <a *ngIf="!loggedin" class="button is-primary" [routerLink]="['/login']">
            Zaloguj
          </a>
        </div>
      </div>
    </div>
  </div>
</nav>
