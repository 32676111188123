import { ActivatedRoute, Router } from '@angular/router';
import { Component } from '@angular/core';
import { Observable, of } from 'rxjs';
import { StateService } from '../services/state.service';

@Component({
  selector: '',
  template: `
    <app-navbar [loggedin]="($isLogged | async) === true"></app-navbar>
    <router-outlet></router-outlet>
    <app-checkout-button></app-checkout-button>
    <app-footer></app-footer>
    <app-cookies-banner></app-cookies-banner>
  `,
  styleUrls: ['./home.layout.css'],
})
export class HomeLayout {
  $isLogged: Observable<boolean> = of(false);


  constructor(
    public state: StateService,
    public route: ActivatedRoute,
    public router: Router
  ) {
    this.$isLogged = this.state.isSignedIn();
    this.route.url.subscribe((url: any) => {
      if (url.length > 0 && url[0].path == "logout")
        this.router.navigateByUrl("/url");
    });
  }

}
