import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { Injectable } from "@angular/core";
import { Cart, CartBuilder, CartItem } from 'src/app/models/cart.model';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  private subject:Subject<Node> = new BehaviorSubject<any>(null);
  private cartSubject:Subject<Cart> = new BehaviorSubject<Cart>(new CartBuilder().build());
  private cartItemsSubject:Subject<CartItem[]> = new BehaviorSubject<CartItem[]>([]);


  sendData(data: any) {
    this.subject.next(data);
  }

  clearData() {
    this.subject.next();
  }

  getData(): Observable<any> {
    return this.subject.asObservable();
  }

  updateCart(cart: any) {
    this.cartSubject.next(cart);
    console.log("cart updated");
  }

  getCart(): Observable<any> {
    return this.cartSubject.asObservable();
  }

  updateCartItems(items: any) {
    this.cartItemsSubject.next(items);
    console.log("cartItems updated");
  }

  getCartItems(): Observable<CartItem[]> {
    return this.cartItemsSubject.asObservable();
  }

  isSignedIn(): Observable<boolean> {
    return of(localStorage.getItem('token') !== null && localStorage.getItem('token') !== '');
  }


}
