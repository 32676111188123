import { Cart, CartItem } from 'src/app/models/cart.model';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { CartBuilder } from 'src/app/models/cart.model';
import { StateService } from 'src/app/services/state.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  @Input() loggedin: boolean = false;
  @ViewChild('navBurger') navBurger: ElementRef = <ElementRef>{};
  @ViewChild('navMenu') navMenu: ElementRef = <ElementRef>{};

  $cartItems: Observable<CartItem[]> = of();
  $cart: Observable<Cart> = of();

  constructor(
    private state: StateService,
  ) {
    let json = localStorage.getItem("cart") || null;
    let cart = new CartBuilder().load(json);
    this.state.updateCart(cart);
    this.state.updateCartItems(cart.CartItems);

    this.$cartItems = this.state.getCartItems();
    this.$cart = this.state.getCart()

  }

  ngOnInit(): void {
  }

  toggleNavbar() {
    this.navBurger.nativeElement.classList.toggle('is-active');
    this.navMenu.nativeElement.classList.toggle('is-active');
  }

}
